// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  envName: 'production',
  production: true,
  hmr: false,
  baseUrl: 'https://www.myframeboard.com/',
  apiUrl: 'https://customerapi.myframeboard.com/api',
  defaultLogo: 'assets/images/essilor-logo-white.svg',
  userAccessURL: '/user/authenticate',
  notificationURL: '/notification',
  brandsURL: '/brands',
  image360Url: 'https://images.myframeboard.com/360/',
  mailjet: {
    tenant: 'MyFrameBoard',
    forgotPwTemplateId: 1391258,
    forgotPwSubject: 'MyFrameBoard.com - Forgot Password',
    forgotPwUrl: 'reset-password/',
    from: 'no-reply@myframeboard.com',
  },
  okta: {
    client: {
      clientId: '0oa3pu74gasByTNBu357',
      redirectUri: 'https://www.myframeboard.com/auth/callback',
      logoutUri: 'https://www.myframeboard.com/auth/logout',
      url: 'https://essilorext.okta.com',
      issuer: 'https://essilorext.okta.com/oauth2/aus3pu6yt7YJlXdPh357',
    },
    oidc: {
      clientId: '0oa3pu74gasByTNBu357',
      aud: '0oa1b53juuoTNmqfl358',
      idp: '0oa1b53juuoTNmqfl358',
      redirectUrl: 'https://www.myframeboard.com/auth/callback',
      issuer: 'https://essilorext.okta.com/oauth2/aus3pu6yt7YJlXdPh357',
      scopes: 'openid profile email visionweb',
      oidc: true,
    },
    authRedirectUrl: '/catalog',
  },
  eceChatAPI: 'https://ece.essilorusa.com/system/egain/chat/entrypoint/agentAvailability/1003',
};
